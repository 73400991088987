@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
  .font-gtwalsheim {
    font-family: 'GT Walsheim', sans-serif;
  }

  .font-arial {
    font-family: Arial, sans-serif;
  }

  .font-gadget {
    font-family: Gadget, sans-serif;
  }

  .font-sans {
    font-family: sans-serif;
  }
}





body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f0f0f0;
  color: #333;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.error-code {
  font-size: 10rem;
  margin: 0;
  font-weight: bold;
  color: #ff6f61;
}

.error-message {
  font-size: 2rem;
  margin: 0.5rem 0;
  font-weight: 500;
}

.error-description {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  max-width: 600px;
}

span[role="img"] {
  font-size: 1.5em;
  margin-right: 0.5rem;
}

@keyframes growWord {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


@keyframes shrinkWord {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.animated-word {
  display: inline-block;
  white-space: nowrap;
}

.grow {
  animation: growWord 1s forwards;
}

.shrink {
  animation: shrinkWord 1s forwards;
}


